const processList = [
  {
    icon: require("../img/icons/processes/discovery.png"),
    name: "Discovery",
    summary: "Provide the planning and specifics necessary to make a product successful."
  },
  {
    icon: require("../img/icons/processes/sketching.png"),
    name: "Sketching",
    summary: "A focused session that aligns ideas for solutions to what is possible given your time and budget."
  },
  {
    icon: require("../img/icons/processes/low-fi.png"),
    name: "Low-fidelity",
    summary: "Designs that focus on layout and concepts rather than style. These allow both teams to speak the same language and agree on solutions."
  },
  {
    icon: require("../img/icons/processes/test.png"),
    name: "Test",
    summary: "User testing, running ads, and creating targeted surveys to guage interest or determine if a designed solution works for real users."
  },
  {
    icon: require("../img/icons/processes/build.png"),
    name: "Build",
    summary: "Create a prototype or production ready application from the previous learnings and designs."
  }
];
export default processList;
