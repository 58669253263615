const workList = {
  learningforward: {
    icon: require("../img/icons/work/learningforward.png"),
    name: "LearningForward",
    text: "RPDC",
    href: "/case-studies/learningforward",
    description: "An online tool to optimize and ease the PD process for school districts.",
  },
  evoke: {
    icon: require("../img/icons/work/world-bank.png"),
    name: "The World Bank",
    text: "Evoke 2.0",
    href: "/case-studies/evoke",
    description: "An augmented reality learning game to teach social entrepreneurship.",
  },
  pdredesign: {
    icon: require("../img/icons/work/pdr.png"),
    name: "Bill & Melinda Gates Foundation",
    text: "PDRedesign",
    href: "/case-studies/pdredesign",
    description: "A digital toolkit to improve professional development in districts.",
  },
  ldc: {
    icon: require("../img/icons/work/ldc.png"),
    name: "Bill & Melinda Gates Foundation",
    text: "LDC Jurying Tool",
    href: "/case-studies/ldc",
    description: "Ensure quality lesson plans through peer jurying at scale.",
  },
};
export default workList;
