export default {
  personaGenerator: {
    icon: require("../img/icons/projects/persona.png"),
    name: "PersonaGenerator",
    href: "https://personagenerator.com/",
    description: "Easily create printable & shareable user personas using a helpful template",
  },
  learningDesignPrinciples: {
    icon: require("../img/icons/projects/ldp.png"),
    name: "Learning Design Principles",
    href: "https://learningdesignprinciples.com/",
    description: "Assess your learning experiences using Pearson's high quality self assessments",
  },
  postcard4: {
    icon: require("../img/icons/projects/sendbunny.png"),
    name: "Sendbunny",
    href: "https://sendbunny.com/",
    description: "Daily videos from your favorite YouTube playlists to your email",
  },
  datasaurus: {
    icon: require("../img/icons/projects/datasaurus.png"),
    name: "Datasaurus",
    href: "https://datasaurus.org/",
    description: "Compare and analyze tax exempt organizations in the U.S. using IRS data",
  },
}
