import React, {Component} from 'react'
import {Link} from 'gatsby'
import caseStudies from './workList'
import projects from './inHouseProjectList'
import {map} from 'lodash'

export default class CaseStudiesList extends Component {
  render() {
    const casestudyCards = map(caseStudies, (cs, i) => {
      const cardImage = {
        background:'url(' + cs.icon + '), linear-gradient(#859ABC, #263554)'
      }
      return (
        <div key={i} className="col-sm-6">
          <Link to={cs.href} className="case-study-card">
            <div className="card-image" style={cardImage}/>
            <div className="card-content">
              <h2 className="h3">{cs.text}</h2>
              <h3 className="h6">{cs.name}</h3>
              <p>{cs.description}</p>
            </div>
          </Link>
        </div>
      )
    })
    const projectCards = map(projects, (cs, i) => {
      const cardImage = {
        background: 'url(' + cs.icon + '), linear-gradient(#859ABC, #263554)',
        backgroundSize: 'cover',
      }
      return (
        <div key={i} className="col-sm-6">
          <a href={cs.href} target="_blank" className="project-card">
            <div className="card-image" style={cardImage}/>
            <div className="card-content">
              <h2 className="h5">{cs.name} <i className="fa fa-external-link"/></h2>
              <p>{cs.description}</p>
            </div>
          </a>
        </div>
      )
    })

    return (
      <div className="row">
        {casestudyCards}
        {projectCards}
      </div>
    )
  }
}
