import {camelCase} from 'lodash'
import {Link} from 'gatsby'
import {Popover, PopoverBody} from 'reactstrap'
import React, {Component} from 'react'

import processList from '../components/processList'

export default class OurProcess extends Component {
  state = {
    isOpen: false,
  }
  toggle = (service) => {
    const {isOpen} = this.state
    if (isOpen === service) {
      this.setState({isOpen: false})
    } else {
      this.setState({isOpen: service})
    }
  }

  render() {
    const {isOpen} = this.state
    return (
      <div className="cards">
        {
          processList.map((process, i) => {
            const camelName = camelCase(process.name)
            return (
              <div key={camelName} className="popover-card" id={camelName}>
                <div className="card-children">
                  <img alt={process.name} src={process.icon} />
                  <label>{process.name}</label>
                </div>
                <Popover
                  isOpen={isOpen === camelName}
                  target={camelName}
                  trigger="click hover"
                  toggle={this.toggle.bind(null, camelName)}
                >
                  <PopoverBody>
                    {process.summary}
                  </PopoverBody>
                </Popover>
              </div>
            )
          })
        }
      </div>
    )

  }
}
