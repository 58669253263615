import {camelCase} from 'lodash'
import {Link} from 'gatsby'
import {UncontrolledPopover, PopoverBody} from 'reactstrap'
import React, {Component} from 'react'

import processList from '../components/processList'
import projectList from '../components/projectList'
import CaseStudiesList from '../components/CaseStudiesList'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'
import OurServices from '../components/OurServices'
import OurProcess from '../components/OurProcess'

import monitor from '../img/monitor.png'

import './index.scss'


export default class Home extends Component {

  goToContact = (e) => {
    e.preventDefault()
    const contactForm = document.getElementById('contact-form');
    if (contactForm) {
      contactForm.scrollIntoView(true)
    }
  }

  render() {
    return (
      <Layout>
        <div className="home-hero">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-7 hero-left">
                <p className="value-prop">We <span className="changing-text">
                  <span className="text-box">
                    <span>collaboratively </span>
                    <span>passionately </span>
                    <span>thoughtfully </span>
                    <span>proudly </span>
                    <span>skillfully </span>
                  </span>
                </span> design and build for social good.</p>
              </div>
              <div className="col hero-right">
                <img className="invisible img-fluid" role="presentation" src={monitor}/>
                <img className="absolute img-fluid" role="presentation" src={monitor}/>
              </div>
            </div>
          </div>
        </div>
        <div className="container home-copy">
          <div className="row">
            <div className="col">
              <h2>We are problem solvers, tool builders, and social do good-ers.</h2>
              <p>Our team consists of experts from diverse backgrounds who are committed to
              building creative, user-focused solutions. We are driven by a single mission: we use our
              talents as designers, developers and product managers to make an immediate positive impact
              in our global community.</p>
              <div className="center">
                <a
                  href="/#contact-form"
                  className="btn btn-lg btn-primary"
                  onClick={this.goToContact}
                >Work with us</a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2>What we're great at.</h2>
              <p>You provide the problem or idea and our team will work to discover the most
              practical solution. We excel at building prototypes, analyzing results, and then
              scaling successful solutions.</p>
              <OurServices/>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2>Our process ensures we build something that meets real-world needs</h2>
              <p>We use our style of design thinking to refine your problem statement and the path to
              solving it. Through quick but thorough testing, we are able to identify what works well
              for your organization and your users.</p>
              <OurProcess />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2>Some of our latest work</h2>
              <p>We love what we do and enjoy sharing in our partners success. These projects represent our
              latest (and probably favorite) products.</p>
              <CaseStudiesList/>
            </div>
          </div>
        </div>
        <ContactForm />
      </Layout>
    )
  }
}
