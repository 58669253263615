const projectList = [
  {
    name: 'web apps',
    summary: 'An application that runs in a browser like Chrome, Firefox, or Internet Explorer.',
    icon: require('../img/icons/web_app.png'),
    links: [
      {
        href:'http://mobility-labs.com/case-studies/pdredesign',
        text:'See PDredesign: A Mobility Labs web app ',
        type:'mobility'
      },
      {
        href:'http://blog.venanti.us/web-app-2015/',
        text:'Some tidbits about web apps ',
        type:'external'
      }
    ]
  },
  {
    name: 'mobile apps',
    summary: 'Applications that run on smart phones. Examples include: PokemonGo, Facebook Messenger, Slack, and Instagram',
    icon: require('../img/icons/mobile_app.png'),
    links: [
      {
        href:'http://pttrns.com/',
        text:'Some mobile design examples to look over ',
        type:'external'
      },
      {
        href:'https://www.smashingmagazine.com/2015/04/thinking-like-an-app-designer/',
        text:'A bit of reading on mobile',
        type:'external'
      },
      {
        href:'https://medium.com/@WebdesignerDepot/how-to-design-for-mobile-ux-423070379559#.d8mmtlgpc',
        text:'Mobile app tidbits ',
        type:'external'
      }
    ]
  },
  {
    name: 'informational websites',
    summary: 'These are microsites and marketing sites. They are often created in a CMS like WordPress or Drupal.',
    icon: require('../img/icons/informational_site.png'),
    links: [
      {
        href:'http://www.smetoolkit.org/smetoolkit/en/content/en/551/Develop-Your-Web-Site-Marketing-Plan',
        text:'Create a plan for your marketing site',
        type:'external'
      },
      {
        href:'http://www.rightsourcemarketing.com/web-design/building-a-website-basics-to-know-before-starting/',
        text:'Website basics',
        type:'external'
      },
      {
        href:'http://www.mobility-labs.com/2015/brand-identity-as-part-of-search-engine-optimization',
        text:'Our thoughts on Branding ',
        type:'mobility'
      },
      {
        href:'http://www.fastcodesign.com/1664145/branding-is-about-creating-patterns-not-repeating-messages',
        text:'Branding is about patterns ',
        type:'external'
      }
    ]
  },
  {
    name: 'discovery for a product',
    summary: 'Determining what should be included in a product. This includes business questions and technical specifications.',
    icon: require('../img/icons/discovery.png'),
    links: [
      {
        href:'http://www.mobility-labs.com/case-studies/ldc-first-steps',
        text:'See Discovery with Mobility Labs and LDC ',
        type:'mobility'
      },
      {
        href:'https://hbr.org/2008/06/design-thinking',
        text:'Design Thinking by the Harvard Business Review',
        type:'external'
      }
    ]
  },
  {
    name: 'user research',
    summary: 'Talking to actual or potential users to determine how successful is a product or design',
    icon: require('../img/icons/user_research.png'),
    links: [
      {
        href:'http://www.mobility-labs.com/2013/user-experience-giving-people-what-they-want',
        text:'User Experience blog by Mobility Labs',
        type:'mobility'
      },
      {
        href:'http://www.mobility-labs.com/2016/teacher-voice-in-the-design-process-at-sxswedu',
        text:'Design in Education by Mobility Labs ',
        type:'mobility'
      },
      {
        href:'http://www.mobility-labs.com/2015/best-practices-in-user-research-with-kids',
        text:'User Research with kids by Mobility Labs ',
        type:'mobility'
      }
    ]
  },
  {
    name: 'user experience',
    summary: 'Shaping the journey of a user through your application or website. This is an excellent exercise to map your goals to user needs.',
    icon: require('../img/icons/visual_design.png'),
    links: [
      {
        href:'https://medium.com/@Mockplus/top-20-ux-design-blogs-and-resources-you-should-follow-in-2016-514b0ddab331#.9pkpatmp2',
        text:'General resources',
        type:'external'
      }
    ]
  },
  {
    name: 'other project',
    summary: 'If nothing mentioned here fits what you are picturing, select this one. Maybe you need branding or graphic design.',
    icon: require('../img/icons/other.png'),
    links: [
      {
        href:'https://medium.com/@Mockplus/top-20-ux-design-blogs-and-resources-you-should-follow-in-2016-514b0ddab331#.9pkpatmp2',
        text:'General resources',
        type:'external'
      },
      {
        href:'http://www.mobility-labs.com/2015/brand-identity-as-part-of-search-engine-optimization',
        text:'Our thoughts on Branding ',
        type:'mobility'
      },
      {
        href:'http://www.fastcodesign.com/1664145/branding-is-about-creating-patterns-not-repeating-messages',
        text:'Branding is about patterns ',
        type:'external'
      }
    ]
  },
  {
    name: 'i\'m not sure',
    summary: 'Maybe you don\'t know exactly what it is right now. We can help you figure it out.',
    icon: require('../img/icons/im_not_sure.png'),
    links: [
      {
        href:'https://medium.com/@Mockplus/top-20-ux-design-blogs-and-resources-you-should-follow-in-2016-514b0ddab331#.9pkpatmp2',
        text:'General resources',
        type:'external'
      }
    ]
  }
];
export default projectList;
